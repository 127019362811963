import { Product } from 'model'
import { formatTestID } from 'core/utils'
import { Money } from 'core/components'
import { Alert, Box, Button, Card, Collapse, Flex, Icon, Stack } from 'ui/core'
import { useRef, useState } from 'react'
import { trackProducts } from 'lib/analytics'
import { CreateSellRequestType, DetailsFormData } from '../types'
import { getSanityClient, PRODUCT_FIELDS } from '@resellam/sanity'
import { useQueryDocuments } from '@aonawale/react-sanity-hooks'
import useEstimateProductPrice from 'hooks/estimateProductPrice'
import { useRouter } from 'next/router'
import { filter, slice } from '@aonawale/sanity-query'
import DetailsForm, { DetailsFormRef } from '../DetailsForm'
import { getProductTypeLabel } from '../utils'

export interface DetailsProps {
  type: CreateSellRequestType,
  product?: Product,
  loading?: boolean,
  estimate?: boolean,
  onNext: (data: DetailsFormData) => void,
  testID?: string,
}

const Details = ({
  type,
  product,
  loading,
  estimate,
  onNext,
  testID,
}: DetailsProps) => {
  const router = useRouter()

  const forProductSlug = router.query.for

  const { data: forProductData, isLoading: _forProductIsLoading }
    = useQueryDocuments<Product>(
      getSanityClient(),
      forProductSlug
        ? {
            type: 'product',
            constraints: [
              filter('slug.current', '==', forProductSlug),
              slice(0, 1),
            ],
          }
        : undefined,
      PRODUCT_FIELDS,
    )

  const forProductIsLoading = _forProductIsLoading || !router.isReady

  const forProduct = forProductData?.[0]

  const value = type

  const sellFormRef = useRef<DetailsFormRef>(null)

  const swapSellFormRef = useRef<DetailsFormRef>(null)
  const swapSwapFormRef = useRef<DetailsFormRef>(null)

  const estimateProductPrice = useEstimateProductPrice()

  const [estimateData, setEstimateData] = useState<{
    price: number,
  } | null>(null)

  const getFormValues = () => {
    const sellValues
      = value === 'swap'
        ? swapSellFormRef.current?.parseValues()
        : sellFormRef.current?.parseValues()

    const sellProduct
      = value === 'swap'
        ? swapSellFormRef.current?.product
        : sellFormRef.current?.product

    const swapProduct
      = value === 'swap' ? swapSwapFormRef.current?.product : undefined

    const swapValues
      = value === 'swap' ? swapSwapFormRef.current?.parseValues() : undefined

    return {
      sellValues,
      sellProduct,
      swapProduct,
      swapValues,
    }
  }

  const track = (eventName: string) => {
    const { sellValues, sellProduct } = getFormValues()
    trackProducts(eventName, {
      products: sellProduct
        ? [
            {
              ...sellProduct,
              variant: sellValues?.variants
                ?.map((variant) => variant.value)
                .join(', '),
            },
          ]
        : undefined,
    })
  }

  const validateForm = () => {
    if (value === 'sell' && sellFormRef.current?.validate().hasErrors)
      return false

    if (
      value === 'swap'
      && [
        swapSellFormRef.current?.validate().hasErrors,
        swapSwapFormRef.current?.validate().hasErrors,
      ].some((val) => val)
    )
      return false

    return true
  }

  const next = () => {
    const { sellValues, sellProduct, swapProduct, swapValues }
      = getFormValues()
    if (!sellProduct)
      return
    onNext({
      product: sellProduct,
      batteryHealth: sellValues?.batteryHealth,
      variants: sellValues?.variants,
      upgrade: swapProduct
        ? {
            product: swapProduct,
            productId: swapProduct.id,
            variants: swapValues?.variants,
          }
        : undefined,
    })
  }

  const getEstimate = async () => {
    if (!validateForm())
      return

    const { sellValues, sellProduct, swapProduct, swapValues }
      = getFormValues()

    if (!sellProduct)
      return

    track('get_product_price_estimate')

    const price = await estimateProductPrice.trigger({
      product: sellProduct,
      batteryHealth: sellValues?.batteryHealth,
      variants: sellValues?.variants,
      swap: swapProduct
        ? {
            product: swapProduct,
            variants: swapValues?.variants,
          }
        : undefined,
    })
    if (price) {
      setEstimateData({
        price,
      })
    } else {
      next()
    }
  }

  const getPrice = () => {
    track('get_product_exact_price')
    next()
  }

  const handleValuesChange = () => {
    setEstimateData(null)
  }

  const handleContinue = () => {
    if (!validateForm())
      return
    next()
  }

  const sellLabel = getProductTypeLabel(product)

  const infoTitle = (() => {
    if (type === 'sell')
      return 'Get up to'
    return (estimateData?.price ?? 0) > -1 ? 'Add up to' : 'Receive up to'
  })()

  return (
    <Stack
      data-testid={testID}
      w={{ base: '100%', md: value === 'sell' ? '50%' : undefined }}
      style={{ justifySelf: 'center' }}
    >
      {value === 'sell' && (
        <Card w="100%">
          <DetailsForm
            label={`Your ${sellLabel}`}
            withBatteryHealth
            ref={sellFormRef}
            product={product}
            key={product?.id}
            loading={loading}
            onChangeProduct={(slug) => {
              router.replace(`/sell/${slug}`)
            }}
            onValuesChange={handleValuesChange}
            testID={formatTestID(testID, 'details-form-sell')}
          />
        </Card>
      )}

      {value === 'swap' && (
        <Flex gap="md" w="100%" direction={{ base: 'column', sm: 'row' }}>
          <Card w="100%">
            <DetailsForm
              label={`Your ${sellLabel}`}
              withBatteryHealth
              ref={swapSellFormRef}
              product={product}
              key={product?.id}
              loading={loading}
              onChangeProduct={(slug) => {
                if (forProductSlug) {
                  router.replace(`/swap/${slug}?for=${forProductSlug}`)
                } else {
                  router.replace(`/swap/${slug}`)
                }
              }}
              onValuesChange={handleValuesChange}
              testID={formatTestID(testID, 'details-form-sell')}
            />
          </Card>
          <Box lh={0} style={{ flexShrink: 0, alignSelf: 'center' }}>
            <Icon name="arrowRight" size={28} />
          </Box>
          <Card w="100%">
            <DetailsForm
              label={`Your next ${sellLabel}`}
              withColor
              placeholder={product?.title}
              ref={swapSwapFormRef}
              product={forProduct}
              loading={forProductIsLoading}
              key={forProduct?.id}
              onChangeProduct={(slug) => {
                router.replace(`/swap/${router.query.product}?for=${slug}`)
              }}
              onValuesChange={handleValuesChange}
              testID={formatTestID(testID, 'details-form-swap')}
            />
          </Card>
        </Flex>
      )}

      {!estimate && (
        <Button
          fullWidth
          onClick={handleContinue}
          data-testid={formatTestID(testID, 'continue-no-estimate')}
        >
          Continue
        </Button>
      )}

      {estimate && !estimateData && (
        <Button
          loading={estimateProductPrice.isMutating}
          onClick={getEstimate}
          data-testid={formatTestID(testID, 'get-estimated-price')}
        >
          Check price
        </Button>
      )}

      {estimate && (
        <Collapse in={!!estimateData} transitionTimingFunction="slide-up">
          <Alert
            title={infoTitle}
            data-testid={formatTestID(testID, 'estimated-price')}
          >
            {estimateData?.price && (
              <Money
                value={Math.abs(estimateData?.price || 0)}
                align="center"
                fw="bold"
                size="xl"
                data-testid={formatTestID(testID, 'price')}
              />
            )}
          </Alert>
          <Button
            mt="md"
            fullWidth
            onClick={getPrice}
            data-testid={formatTestID(testID, 'get-exact-price')}
          >
            Continue
          </Button>
        </Collapse>
      )}
    </Stack>
  )
}

export default Details
