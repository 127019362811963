import { Flex, Text, ButtonCard } from 'ui/core'
import { Notification } from 'model'
import Link from 'next/link'
import dayjs from 'dayjs'
import { useReadNotification } from 'lib/notification'

export interface NotificationCardProps {
  notification: Notification,
  onClick?: () => void,
}

const NotificationCard = ({ notification, onClick }: NotificationCardProps) => {
  const [readNotification] = useReadNotification()

  const handleClick = () => {
    readNotification({ notificationId: notification.id })
    onClick?.()
  }

  const resourceId = notification.resourceId || notification.data?.sellRequestId
  const resourceType
    = notification.resourceType === 'sell-requests'
      ? 'requests'
      : notification.resourceType
        || (notification.data?.sellRequestId ? 'requests' : undefined)

  return (
    <ButtonCard
      component={Link}
      href={`/${resourceType}/${resourceId}`}
      onClick={handleClick}
    >
      <Flex justify="space-between" w="100%" gap="md">
        <Text ta="left" lineClamp={2} style={{ textWrap: 'wrap' }}>{notification.body}</Text>
        <Text
          size="sm"
          color="gray"
          style={{ flexShrink: 0 }}
        >
          {dayjs(notification.createdAt).fromNow()}
        </Text>
      </Flex>
    </ButtonCard>
  )
}

export default NotificationCard
