import { StepHeader, StepNavButtons } from 'components'
import { Consent, SectionHeader } from 'core/components'
import { useNotifications } from 'core/hooks'
import { capitalize, formatTestID } from 'core/utils'
import analytics from 'lib/analytics'
import { useCreateSellRequest } from 'lib/sell-request'
import { Product, SellRequest } from 'model'
import { useRouter } from 'next/router'
import { Center, Stack } from 'ui/core'
import { useAuth } from 'hooks/use-auth'
import {
  SellRequestProductDetail,
  SellRequestProductIssues,
} from '@resellam/sell-request'
import UploadPhotos from '../UploadPhotos'
import { CreateSellRequestData, CreateSellRequestType } from '../types'
import { buildSellRequest } from '../utils'

export interface ConfirmationProps {
  product: Product,
  data: CreateSellRequestData,
  onBack: () => void,
  onSaveStart: () => void,
  onSaveEnd: () => void,
  testID?: string,
  type: CreateSellRequestType,
}

const Confirmation = ({
  product,
  data,
  type,
  testID,
  onBack,
  onSaveStart,
  onSaveEnd,
}: ConfirmationProps) => {
  const auth = useAuth()
  const router = useRouter()
  const notifications = useNotifications()
  const [createSellRequest, createSellRequestState] = useCreateSellRequest()

  const sellRequest: Partial<SellRequest> = {
    ...data.details,
    ...data.issues,
    product,
  }

  const create = async () => {
    await auth.requireAuth()

    analytics.track('create_sell_request', {
      category: 'ecommerce',
      product: product.title,
      product_category: product.category?.slug,
    })

    onSaveStart()

    const notification = notifications.show({
      loading: true,
      title: 'Sending request',
      message: 'Please wait, this may take a few seconds.',
      autoClose: false,
      withCloseButton: false,
    })

    const createData = await buildSellRequest(product, data)

    const createdSellRequest = await createSellRequest(createData)

    notification.update({
      action: 'create',
      variant: createdSellRequest ? 'success' : 'error',
      entity: 'request',
      autoClose: true,
      loading: false,
      withCloseButton: true,
    })

    onSaveEnd()

    if (createdSellRequest)
      router.push(`/requests/${createdSellRequest.id}`)
  }

  const actionName = capitalize(type)

  return (
    <>
      <StepHeader title="Confirmation" testID={formatTestID(testID, 'header')} />
      <Stack gap={40}>
        <SellRequestProductDetail
          product={product}
          swapProduct={data.details?.upgrade?.product}
          sellRequest={sellRequest}
          testID={formatTestID(testID, 'detail')}
        />
        <div>
          <SectionHeader title="Photos" />
          <UploadPhotos
            readonly
            initialValue={data.photos?.photos}
            testID={formatTestID(testID, 'photos')}
          />
        </div>
        <SellRequestProductIssues
          sellRequest={sellRequest}
          testID={formatTestID(testID, 'issues')}
        />
        <Center>
          <Consent action={actionName} testID={formatTestID(testID, 'consent')} />
        </Center>
      </Stack>
      <StepNavButtons
        back={{ onClick: onBack, disabled: createSellRequestState.isRunning }}
        next={{
          onClick: create,
          children: actionName,
          loading: createSellRequestState.isRunning,
        }}
        testID={formatTestID(testID, 'nav-buttons')}
      />
    </>
  )
}

export default Confirmation
