import { Product, SellRequestVariant } from 'model'
import {
  batteryHealthSchema,
  formatTestID,
  getFormVariants,
  getProductVariants,
  isAppleSmartphone,
  variantsSchema,
} from 'core/utils'
import {
  BatterySlider,
  ProductDetailWrapper,
  ProductVariantInput,
} from 'core/components'
import { Stack } from 'ui/core'
import { forwardRef, Ref, useImperativeHandle, useMemo } from 'react'
import { ProductAutocomplete } from 'components/ProductSearch'
import { z } from 'zod'
import { useForm, zodResolver } from 'ui/form'

type DetailFormValues = {
  productId: string,
  variants: Record<string, string>,
  batteryHealth?: number,
}

type ParsedDetailFormValues = Omit<DetailFormValues, 'variants'> & {
  variants: SellRequestVariant[],
}

export interface DetailsFormRef {
  validate: () => { hasErrors: boolean },
  parseValues: () => ParsedDetailFormValues | undefined,
  product?: Product,
}

export interface DetailsFormProps {
  testID?: string,
  withBatteryHealth?: boolean,
  withColor?: boolean,
  label?: string,
  product?: Product,
  loading?: boolean,
  placeholder?: string,
  onChangeProduct?: (slug: string) => void,
  onValuesChange?: (values: Partial<DetailFormValues>) => void,
}

const DetailsForm = forwardRef(
  (
    {
      testID,
      withBatteryHealth,
      withColor,
      label,
      product,
      loading,
      placeholder,
      onChangeProduct,
      onValuesChange,
    }: DetailsFormProps,
    ref: Ref<DetailsFormRef>,
  ) => {
    const productVariants = useMemo(
      () =>
        product?.variants?.filter((variant) =>
          withColor
            ? true
            : variant.variantInputType !== 'color'
              && variant.variantName !== 'Color',
        ),
      [product, withColor],
    )

    const showBatteryHealth = withBatteryHealth && isAppleSmartphone(product)

    const schema = useMemo(
      () =>
        z
          .object({
            productId: z
              .string({
                required_error: `Choose ${label?.toLowerCase()}`,
              })
              .nonempty(),
            variants: variantsSchema(productVariants),
            batteryHealth: batteryHealthSchema(showBatteryHealth),
          })
          .strict(),
      [productVariants, showBatteryHealth, label],
    )

    const form = useForm<Partial<DetailFormValues>>({
      initialValues: {
        productId: product?.id,
        variants: getFormVariants(productVariants, undefined, (variant) => {
          const validValues = variant.variantValue
          return validValues?.length === 1 ? validValues?.[0].key : undefined
        }),
        ...(showBatteryHealth ? { batteryHealth: 0 } : {}),
      },
      validate: zodResolver(schema),
      onValuesChange,
    })

    const variantsInput = useMemo(
      () => getProductVariants(productVariants),
      [productVariants],
    )

    useImperativeHandle(
      ref,
      () => ({
        validate: () => form.validate(),
        parseValues: () => schema.parse(form.getValues()),
        product,
      }),
      [form, product, schema],
    )

    return (
      <Stack data-testid={testID}>
        <ProductAutocomplete
          value={product?.title}
          placeholder={placeholder}
          label={label}
          error={form.errors.productId}
          onChange={(hit) => {
            if (hit?.slug) {
              onChangeProduct?.(hit?.slug)
            }
            form.setValues({
              productId: hit?.objectID || '',
              variants: {},
              ...(showBatteryHealth ? { batteryHealth: 0 } : {}),
            })
          }}
        />
        {(product || loading) && (
          <ProductDetailWrapper
            loading={loading}
            product={product}
            cols={{ base: 1 }}
            imageSize={280}
          >
            {variantsInput?.length || showBatteryHealth ? (
              <Stack>
                {variantsInput.map((variant) => (
                  <ProductVariantInput
                    key={variant.label}
                    {...variant}
                    {...form.getInputProps(`variants.${variant.label}`)}
                    required
                    testID={formatTestID(
                      testID,
                      variant.label,
                      'product-variant-input',
                    )}
                  />
                ))}
                {showBatteryHealth && (
                  <BatterySlider
                    showHelpText
                    label="Battery health"
                    {...form.getInputProps('batteryHealth')}
                    testID={formatTestID(testID, 'battery-slider')}
                  />
                )}
              </Stack>
            ) : null}
          </ProductDetailWrapper>
        )}
      </Stack>
    )
  },
)

export default DetailsForm
